// @ts-check

/**
 * @param {string} href
 * @returns {void}
 */
export const applyBranding = href => {
    if (isBadHref(href)) return;

    const iconIcoRef = document.querySelector('link[rel="icon"]');
    const iconExtRef = document.querySelector('link[type="image/svg+xml"]');
    const iconAplRef = document.querySelector('link[rel="apple-touch-icon"]');

    if (iconIcoRef) iconIcoRef.replaceWith(brandedIconIco(href));

    if (iconExtRef) iconExtRef.replaceWith(brandedIconExt(href));

    if (iconAplRef) iconAplRef.replaceWith(brandedIconApl(href));
};

/**
 * @param {unknown} href
 * @returns {boolean}
 */
const isBadHref = href => {
    if (typeof href !== 'string') return true;

    return href.includes('undefined') || href.includes('null');
};

/**
 * @param {string} href
 * @returns {HTMLLinkElement}
 */
const brandedIconIco = href => {
    const element = document.createElement('link');

    element.rel = 'icon';
    element.sizes = '32x32';
    element.href = href;

    return element;
};

/**
 * @param {string} href
 * @returns {HTMLLinkElement}
 */
const brandedIconExt = href => {
    const element = document.createElement('link');

    element.rel = 'icon';
    element.type = 'image/svg+xml';
    element.href = href;

    return element;
};

/**
 * @param {string} href
 * @returns {HTMLLinkElement}
 */
const brandedIconApl = href => {
    const element = document.createElement('link');

    element.rel = 'apple-touch-icon';
    element.href = href;

    return element;
};

// @ts-check

import { useState } from 'react';
import { Box, Button } from '@mui/material';

import stopPropagation from './stopPropagation';

export default function copyToClipboard(string) {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) return navigator.clipboard.writeText(string);

    return Promise.reject('The Clipboard API is not available.');
}

export function ClickToCopy({ string, children }) {
    const [copied, setCopied] = useState(false);

    const handleClick = event => {
        stopPropagation(event);
        copyToClipboard(string).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 1000);
        });
    };

    return (
        <Button
            onClick={handleClick}
            variant="text"
            sx={{ cursor: 'pointer', textTransform: 'none', py: 0, px: 2, mx: -2, height: 'unset', display: 'inline', position: 'relative', verticalAlign: 'unset' }}
        >
            {copied && <Box sx={{ position: 'absolute', zIndex: '1', left: '50%', top: '0', transform: 'translateX(-50%)', fontWeight: '700' }}>Copied</Box>}
            <Box sx={{ opacity: copied && '0.1' }}>{children}</Box>
        </Button>
    );
}

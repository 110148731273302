export const renderPropertyAddressAndName = property => {
    if (property) {
        return (
            (property.name != null ? property.name + ' - ' : '') +
            property.address +
            ' ' +
            (property.address2 ? property.address2 : '') +
            ' ' +
            property.city +
            ', ' +
            property.stateID
        );
    }
};

export const renderPropertyAddress = property => {
    if (property) {
        return property.address + ' ' + (property.address2 ? property.address2 : '') + ' ' + property.city + ', ' + property.stateID;
    }
};
